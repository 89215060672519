/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Row, Col, Image, ListGroup, Card, Button, ListGroupItem, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
//import products from '../products'
import { Link } from 'react-router-dom'
import Rating from '../components/Rating'
import {
  listInfoDetails,
} from '../actions/InfoActions'
import Meta from '../components/Meta'
import Loader from '../components/Loader'
import Message from '../components/Message'
import QRCode  from 'qrcode.react';
import banner from '../static/image/banner.jpeg'


const InfoScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1)
  const [classifyType, setClassifyType] = useState('oneMonth')
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [price, setPrice] = useState(1)
  const dispatch = useDispatch()
  //=> product.productKey === match.params.productKey )
  const infoDetails = useSelector((state) => state.infoDetails)
  const { loading, error, info } = infoDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const pageNumber = match.params.pageNumber || 1
  const areaIdNum = match.params.areaIdNum || 6
  const areaSecondIdNum = match.params.areaSecondIdNum

  useEffect(() => {
      let appUserId = null;
      let token = null;
      if (userInfo) {
        appUserId = userInfo.id;
        token = userInfo.token;
      }
      let infoParam = { 
        id: match.params.id,
        appUserId: appUserId,
        token: token,
      }

      dispatch(listInfoDetails(infoParam))
    // eslint-disable-next-line
  }, [dispatch, match])

  // const imageClick = () => {
  //   window.location.href = 'http://ylcc.yilelive.com/other/2024/03/10000_021325996TianXin_3.23_0309_1407.apk'
  // }

  return (
    <>
      {/* <Row>
          <img src={banner} source={banner} alt="banner" style={{ width: "100%", height: 300, marginBottom: 20 }} onClick={() => imageClick()}/>
      </Row> */}
      
      <Link className='btn btn-dark my-3' to={`/home/${areaIdNum}/${areaSecondIdNum}/${pageNumber}`}>
        返回主页
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title={info.title} />
          <Row>
            <Col md={3}>
              <Image src={info.icon} alt={info.title} fluid />
            </Col>

            <Col md={4}>
              <ListGroup variant='flush'>
                  <ListGroup.Item><h5>{info.title}</h5></ListGroup.Item>
                  <ListGroup.Item>时间：{info.addTime} 浏览数：{info.viewNum}</ListGroup.Item>
                  <ListGroup.Item>所属地区：{info.areaName}</ListGroup.Item>
                  <ListGroup.Item>小姐年龄：{info.age}</ListGroup.Item>
                  <ListGroup.Item>小姐颜值：{info.faceScore}</ListGroup.Item>
                  <ListGroup.Item>消费水平：{info.consumption}</ListGroup.Item>
                  <ListGroup.Item>服务项目：{info.serviceItem}</ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md={5}>
              <ListGroup variant='flush'>
                  <ListGroup.Item><h5>联系方式</h5></ListGroup.Item>
                  <ListGroup.Item>qq/微信：{info.qqWx}</ListGroup.Item>
                  <ListGroup.Item>电话号码：{info.mobile}</ListGroup.Item>
                  <ListGroup.Item>详细地址：{info.address}</ListGroup.Item>
              </ListGroup>
            </Col>

          </Row>
          <Row>
            <Col md={12}>
              <ListGroup variant='flush'>
                  <ListGroup.Item><h5>详细介绍</h5></ListGroup.Item>
                  <ListGroup.Item>{info.des}</ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            { 
                info.portraitList && info.portraitList.map((portrait,index) => (
                    <Col key={index} sm={12} md={6} lg={4} xl={3}>
                        <Image src={portrait} fluid />
                    </Col>
            ))}
          </Row>
          </>
      )}
    </>
  ) 
}

export default InfoScreen
